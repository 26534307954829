<template>
    <div>
        <b-card-code title="Detalles de cuenta">
            <b-row>
                <b-col md="6" lg="4" xl="4" offset-md="3" offset-lg="4" offset-xl="4" class="mb-1">
                    <b-form-group label="Email" label-for="basicInput">
                    <b-form-input v-model="email" placeholder="Digite"/>
                    </b-form-group>
                </b-col>   
            </b-row>
             <b-row>
                <b-col md="6" lg="4" xl="4" offset-md="3" offset-lg="4" offset-xl="4" class="mb-1">
                    <b-form-group>
                        <div class="d-flex justify-content-between">
                        <label for="login-password">Contraseña</label>
                        <b-link @click="toggleChangePassword">
                            <small>Cambiar Contraseña</small>
                        </b-link>
                        </div>
                        <b-input-group class="input-group-merge">
                            <b-form-input :disabled="!changePassword" id="login-password" v-model="password" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="··········"/>
                            <b-input-group-append is-text>
                                <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility"/>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>  
               
            </b-row>
            <b-col md="6" lg="4" xl="4" offset-md="3" offset-lg="4" offset-xl="4" class="mb-1">
                <b-form-group label="Foto de Perfil" label-for="basicInput">
                    <b-form-file placeholder="Seleccione una Imagen" v-model="profilePitureFile" />
                    <b-card-text @click="openDocument(profilePictureUrl)" v-if="profilePictureUrl" accept="image/*" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                </b-form-group>
            </b-col>      
        </b-card-code>

        <b-card-code>
            <b-row>
                <b-col v-if="!processing" xl="2" lg="2" offset-lg="5" offset-xl="5">
                    <b-button variant="primary" block @click="saveAccountDetails">Guardar</b-button>
                </b-col>

                <b-col v-if="processing" xl="2" lg="2" offset-lg="5" offset-xl="5">
                    <b-button variant="primary" disabled block><b-spinner small label="Cargando..." /></b-button>
                </b-col>
            </b-row>                   
        </b-card-code>
    </div>
  
</template>

<script>
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getAbilitiesBasedOnUserType } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner, BFormFile,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,    
    BSpinner,
    BFormFile,
    BCardCode,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userID: null,
      email: null,
      password: null,
      profilePictureUrl: null,
      profilePitureFile: null,
      changePassword: false,
      processing: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    var userData = getUserData();
    this.userID = userData.userID;
    this.email = userData.email;
    this.profilePictureUrl = userData.profilePictureUrl;
  },
  watch:{
      profilePitureFile: function(val) { this.profilePitureFile = val; this.uploadFile(val, 'profilePitureFile'); },
  },
  methods: {
    saveAccountDetails() {        
        this.processing = true;
        axios.get('/User/EditAccount', { params: { userID: this.userID, email: this.email, profilePictureUrl: this.profilePictureUrl, password: this.password, changePassword: this.changePassword }})
        .then(response => {
          this.processing = false;
          console.log(response);
          if(response.data) {
              localStorage.setItem('userData', JSON.stringify(response.data));
              this.$bvToast.toast("Información guardada exitosamente", { title: 'Ok', variant: 'success', solid: true, })     
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }
          
        })
        .catch(error => {
          this.processing = false;
          if(error.response){
            this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
          } else {
            this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
          }
        })
    },
    uploadFile(fileToUpload, fileType) {
        this.processing = true;               
        let formData = new FormData();
        formData.append('file', fileToUpload);
        axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          this.processing = false;
          if(response.data) {
            this.setFileUrl(fileType, response.data);
            this.$bvToast.toast("Imagen subida de forma correcta", { title: 'Ok', variant: 'success', solid: true, })     
          } else {
            this.$bvToast.toast("Ocurrió un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    setFileUrl(fileType, fileUrl) {
        switch(fileType) {
            case 'profilePitureFile':
                this.profilePictureUrl = fileUrl;
                break;
        }
    },
    openDocument(fileUrl) {
        window.open(fileUrl, '_blank');
    },
    toggleChangePassword() {
        this.changePassword = !this.changePassword;
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
